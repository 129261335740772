<template>
  <div class="container-fluid">
      <b-row>
        <b-col sm="12" md="7" lg="7" xl="7" class="bg-signup align-items-center d-none d-lg-flex">
          <div class="ml-5">
            <img alt="WYA Yoga" src="../../assets/images/logo/logo.png">
            <h1 class="text-white mt-3 font-weight-bold display-3">{{ $t('WORLD YOGA ALLIANCE') }}</h1>
            <h5 class="text-white mt-4">{{ $t('International Yoga Organization') }}</h5>
            <br>
             <b-button pill variant="light" :to="{ name: 'home' }" class="mr-3 px-5 my-4 font-weight-bold header-btn-register">{{ $t('Learn more') }}</b-button>
            <b-button pill variant="outline-light" :to="{ name: 'searchDirectory' }" class="mr-3 px-5 my-4 ml-2 font-weight-bold header-btn-register">{{ $t('Search') }}</b-button>
          </div>
        </b-col>
        <b-col sm="12" md="12" lg="5" xl="5" class="d-flex justify-content-center align-items-center">
          <div class="mt-5 mt-lg-0 px-3 pt-3">
            <div class="d-flex justify-content-center"><h1 class="text-primary-light d-inline font-weight-bold header-signup">{{ $t('Sign up') }}</h1></div>
            <p class="text-center text-gray-light mt-5">{{ $t('Sign up to get started the Yoga Journey With WYA') }} <br>{{ $t('Aready have an account?') }} <router-link :to="{ name: 'authenLogin' }" class="text-danger-light">{{ $t('Login') }}</router-link></p>
            <div class="mt-5">
              <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
                <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
                  <validation-provider
                    :name="$t('Email')"
                    rules="required|email"
                    autocomplete="off"
                    v-slot="{errors}"
                  >
                    <b-form-group class="font-weight-bold text-primary-dark" for="input-username" :label="$t('Email')+'*'">
                      <b-form-input type="email" id="input-username" class="input-auth" v-model="form.username" :placeholder="$t('Email')"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    :name="$t('Create a password')"
                    rules="verify_password|min:8|confirmed:form.password2"
                    v-slot="{errors}"
                  >
                    <b-form-group class="font-weight-bold text-primary-dark" for="input-password1" :label="$t('Create a password')+'*'">
                      <b-form-input id="input-password1" class="input-auth" autocomplete="new-password" v-model="form.password1" type="password" :placeholder="$t('Create a password')"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    :name="$t('Confirm a password')"
                    rules="verify_password|min:8"
                    v-slot="{errors}"
                    autocomplete="off"
                    vid="form.password2"
                  >
                    <b-form-group class="font-weight-bold text-primary-dark" for="input-password2" :label="$t('Confirm a password')+'*'">
                      <b-form-input type="password" id="input-password2" autocomplete="new-password" v-model="form.password2" class="input-auth" :placeholder="$t('Confirm a password')"></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    :name="$t('I accept the Terms and conditions')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-radio value="1" id="input-accept" v-model="isAcceptPolicy" class="mt-3">{{ $t('I accept the') }} <a href="/legal/terms-and-conditions" target="_blank" class="text-danger-light" title="Read the Terms and conditions">{{ $t('Terms and conditions') }}</a></b-form-radio>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <small class="text-danger" v-if="textError" v-html="textError"></small>
                  <b-button pill block type="submit" :disabled="invalid || isLoading || !isAcceptPolicy" class="mr-3 mt-2 px-5 my-4 font-weight-bold btn-primary-dark btn-lg">{{ $t('Sign up') }}</b-button>
                </b-form>
              </ValidationObserver>
            </div>
            <div class="text-center">
              <span class="text-gray-light d-block mt-5">
                Powered By Government of INDIA and THAILAND <br>
                <small class="text-gray-light">Copyright © World Yoga Alliance® 2011 - {{ currentYear }}</small>
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
  </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'Signup',
  title: ' | Sign up',
  data () {
    return {
      currentYear: new Date().getFullYear(),
      form: {
        username: '',
        password1: '',
        password2: ''
      },
      isAcceptPolicy: false,
      isLoading: false,
      textError: ''
    }
  },
  components: {
    ValidationObserver
  },
  mounted () {
    window.scrollTo(0, 0)
    if (this.$store.getters.loggedIn) {
      this.$router.push({ name: 'home' })
    }
  },
  methods: {
    onSubmit () {
      this.$refs.form.validate().then(result => {
        if (result) {
          this.isLoading = true
          this.textError = false
          this.$store.dispatch('register', this.form)
            .then((response) => {
              this.isLoading = false
              this.$notify({
                group: 'success',
                text: this.$t('Signup new account successfully')
              })
              this.$router.push({ name: 'authenLogin' })
            })
            .catch((error) => {
              this.isLoading = false
              let txtError = ''
              if (error.response.data) {
                for (const data in error.response.data) {
                  txtError += error.response.data[data].join('<br>') + '<br>'
                }
                this.textError = txtError
              } else {
                this.textError = this.$t('Invalid username or password')
              }
            })
        }
      })
    }
  }
}
</script>
<style scoped>
  .bg-signup {
    /*background-image: url('../../assets/images/banner/bg_signup.png');
    background-repeat: no-repeat;
    background-size: auto;
    min-height: 900px;*/
  }
  .header-signup {
    /*padding-bottom: 10px;
    border-bottom: 2px solid #F04B4C;*/
  }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('b-row',[_c('b-col',{staticClass:"bg-signup align-items-center d-none d-lg-flex",attrs:{"sm":"12","md":"7","lg":"7","xl":"7"}},[_c('div',{staticClass:"ml-5"},[_c('img',{attrs:{"alt":"WYA Yoga","src":require("../../assets/images/logo/logo.png")}}),_c('h1',{staticClass:"text-white mt-3 font-weight-bold display-3"},[_vm._v(_vm._s(_vm.$t('WORLD YOGA ALLIANCE')))]),_c('h5',{staticClass:"text-white mt-4"},[_vm._v(_vm._s(_vm.$t('International Yoga Organization')))]),_c('br'),_c('b-button',{staticClass:"mr-3 px-5 my-4 font-weight-bold header-btn-register",attrs:{"pill":"","variant":"light","to":{ name: 'home' }}},[_vm._v(_vm._s(_vm.$t('Learn more')))]),_c('b-button',{staticClass:"mr-3 px-5 my-4 ml-2 font-weight-bold header-btn-register",attrs:{"pill":"","variant":"outline-light","to":{ name: 'searchDirectory' }}},[_vm._v(_vm._s(_vm.$t('Search')))])],1)]),_c('b-col',{staticClass:"d-flex justify-content-center align-items-center",attrs:{"sm":"12","md":"12","lg":"5","xl":"5"}},[_c('div',{staticClass:"mt-5 mt-lg-0 px-3 pt-3"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('h1',{staticClass:"text-primary-light d-inline font-weight-bold header-signup"},[_vm._v(_vm._s(_vm.$t('Sign up')))])]),_c('p',{staticClass:"text-center text-gray-light mt-5"},[_vm._v(_vm._s(_vm.$t('Sign up to get started the Yoga Journey With WYA'))+" "),_c('br'),_vm._v(_vm._s(_vm.$t('Aready have an account?'))+" "),_c('router-link',{staticClass:"text-danger-light",attrs:{"to":{ name: 'authenLogin' }}},[_vm._v(_vm._s(_vm.$t('Login')))])],1),_c('div',{staticClass:"mt-5"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"required|email","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"font-weight-bold text-primary-dark",attrs:{"for":"input-username","label":_vm.$t('Email')+'*'}},[_c('b-form-input',{staticClass:"input-auth",attrs:{"type":"email","id":"input-username","placeholder":_vm.$t('Email')},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Create a password'),"rules":"verify_password|min:8|confirmed:form.password2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"font-weight-bold text-primary-dark",attrs:{"for":"input-password1","label":_vm.$t('Create a password')+'*'}},[_c('b-form-input',{staticClass:"input-auth",attrs:{"id":"input-password1","autocomplete":"new-password","type":"password","placeholder":_vm.$t('Create a password')},model:{value:(_vm.form.password1),callback:function ($$v) {_vm.$set(_vm.form, "password1", $$v)},expression:"form.password1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Confirm a password'),"rules":"verify_password|min:8","autocomplete":"off","vid":"form.password2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"font-weight-bold text-primary-dark",attrs:{"for":"input-password2","label":_vm.$t('Confirm a password')+'*'}},[_c('b-form-input',{staticClass:"input-auth",attrs:{"type":"password","id":"input-password2","autocomplete":"new-password","placeholder":_vm.$t('Confirm a password')},model:{value:(_vm.form.password2),callback:function ($$v) {_vm.$set(_vm.form, "password2", $$v)},expression:"form.password2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('I accept the Terms and conditions'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{staticClass:"mt-3",attrs:{"value":"1","id":"input-accept"},model:{value:(_vm.isAcceptPolicy),callback:function ($$v) {_vm.isAcceptPolicy=$$v},expression:"isAcceptPolicy"}},[_vm._v(_vm._s(_vm.$t('I accept the'))+" "),_c('a',{staticClass:"text-danger-light",attrs:{"href":"/legal/terms-and-conditions","target":"_blank","title":"Read the Terms and conditions"}},[_vm._v(_vm._s(_vm.$t('Terms and conditions')))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.textError)?_c('small',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.textError)}}):_vm._e(),_c('b-button',{staticClass:"mr-3 mt-2 px-5 my-4 font-weight-bold btn-primary-dark btn-lg",attrs:{"pill":"","block":"","type":"submit","disabled":invalid || _vm.isLoading || !_vm.isAcceptPolicy}},[_vm._v(_vm._s(_vm.$t('Sign up')))])],1)]}}])})],1),_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"text-gray-light d-block mt-5"},[_vm._v(" Powered By Government of INDIA and THAILAND "),_c('br'),_c('small',{staticClass:"text-gray-light"},[_vm._v("Copyright © World Yoga Alliance® 2011 - "+_vm._s(_vm.currentYear))])])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }